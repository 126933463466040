<template>
    <div class="container">
      <div class="list-option">
        <div @click="() => $refs.paymentHistorySortModal.show()" class="ui-btn">
          {{ activedSortOption.label }}
          <icon class="icon" src="img/common/tab-unfold.png" width="1.2rem" height="5.5rem" :cover="false" />
        </div>
        <div @click="selectPayDate" class="ui-btn">
          {{ payDateText }}
          <icon class="icon" src="img/common/tab-unfold.png" width="1.2rem" height="5.5rem" :cover="false" />
        </div>
      </div>
      
      <template v-if="!init || offerData.length">
        <ul class="payment-list" ref="container" >
          <template v-for="(row, key) in offerData">
            <o-item :key="key" :row="row" />
          </template>
        </ul>
      </template>
      <blank-list v-else :text="emptyText" ref="container" />

      <Modal ref="paymentHistorySortModal" :useFooter="false">
        <ul slot="body">
          <li
            class="text-center"
            v-for="(row, key) in sortOptions"
            :key="key"
            @click="onClickPaymentHistorySortModalItem(row)"
            :class="{ on: paymentHistoryPayload.orderBy === row.value }"
          >
            {{ row.label }}
            <div v-show="key < sortOptions.length - 1" class="ui-border-line"></div>
          </li>
        </ul>
      </Modal>
    </div>
</template>
<script>
import OItem from '@/components/client/OItem'
import BlankList from '@/components/blank/BlankList'
import Icon from '@/components/common/Icon.vue'
import Modal from '@/components/common/Modal.vue'

export default {
  name: 'PaymentHistoryList',
  components: {
    OItem,
    BlankList,
    Modal,
    Icon
  },
  data() {
    return {
      init: false,
      offerData: [],
      offset: 0,
      scroll: {
        lastY: 0,
      },
      que: {
        offerData: false,
      },
      history: {
        exit: false,
      },
      paymentHistoryPayload: {
        orderBy: 'payDate',
        payDate: undefined,
      },
      emptyText: '결제(섭외)된 건이 없습니다.\n섭외율을 높이고 싶다면\n프로필을 꾸준히 관리하세요!'
    }
  },
  computed: {
    userData: {
      get() {
        return this.$store.state.user.userData
      },
    },
    sortOptions() {
      return [
        {
          label: '최근 결제 순',
          value: 'payDate',
        },
        {
          label: '가까운 행사일',
          value: 'playDate',
        },
      ]
    },
    activedSortOption() {
      return this.sortOptions.find(({ value }) => value === this.paymentHistoryPayload.orderBy)
    },
    payDateText() {
      if (this.paymentHistoryPayload.payDate) {
        const year = this.paymentHistoryPayload.payDate.slice(0, 4)
        const month = this.paymentHistoryPayload.payDate.slice(4)
        return `${year}년 ${Number(month)}월`
      }

      return '전체 기간'
    },
  },
  watch: {
    'paymentHistoryPayload.payDate'() {
        this.getPaymentHistoryData(true)
    },
  },
  created() {
    this.$eventBus.$on('refresh', route => {
      if (route.path === '/artist/payment') {
        this.$nextTick()
          .then(() => {
            this.$refs?.container?.scrollTo?.({ top: 0 })
          })
          .then(() => {
            this.getPaymentHistoryData()
          })
      }
    })

    // this.getPaymentHistoryData()
  },
  
  mounted() {
    this.$refs.container.addEventListener('scroll', this.getDataOnScroll, false)
  },

  beforeRouteEnter(to, from, next) {
    next(Vue => {
      if (Vue.$route.query.payDate) {
        Vue.paymentHistoryPayload.payDate = Vue.$route.query.payDate
        Vue.paymentHistoryPayload.orderBy = 'payDate'
      } else if (Vue.paymentHistoryPayload.payDate) {
        Vue.paymentHistoryPayload.payDate = undefined
        Vue.paymentHistoryPayload.orderBy = 'payDate'
      }

      if (Vue.init) { 
        Vue.$refs.container.scrollTop = Vue.scroll.lastY
      } else {
        Vue.getPaymentHistoryData()
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      if (this.$store.state.history.back) {
        this.$runToApp({
          androidCb: () => {
            if (!this.history.exit) {
              this.$toast('뒤로 버튼을 한번 더 누르시면 종료됩니다.')
              this.history.exit = true
              setTimeout(() => (this.history.exit = false), 2000)
            } else {
              location.href = 'hbscheme://appExit'
            }
            next(false)
          },
          iosCb: () => next(false),
          webCb: () => next(false),
        })
      } else {
        this.scroll.lastY = this.$refs.container.scrollTop
        next()
      }
    })
  },
  methods: {
    getPaymentHistoryData(init) {
      if (['PaymentHistoryList'].indexOf(this.$route.name) === -1) return
      if (this.que.offerData) return

      this.que.offerData = true

      if (init) {
        this.init = false
        this.offset= 0
      }

      const req = {
        method: 'post',
        url: `/client/offer/${this.offset}/20`,
        data: {
          artistidx: this.userData.artistidx,
          proPrice: 'y',
          nowState: 'pay,end',
          ...this.paymentHistoryPayload
        },
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let paymentHistory = data.response.offerData || [];
            this.offerData = init ? paymentHistory : this.offerData.concat(paymentHistory)
            this.offset += paymentHistory.length;
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
        .then(() => {
          this.init = true
          setTimeout(() => {
            this.que.offerData = false
          }, 1000)
        })
    },

    getDataOnScroll() {
      if (['PaymentHistoryList'].indexOf(this.$route.name) === -1) return
      let scrollTop = this.$refs.container.scrollTop
      let scrollHeight = this.$refs.container.scrollHeight

      if (scrollHeight <= scrollTop + window.innerHeight) {
        this.getPaymentHistoryData()
      }
    },

    onClickPaymentHistorySortModalItem(row) {
      this.$refs.paymentHistorySortModal?.hide?.()
      this.paymentHistoryPayload.orderBy = row.value
      this.getPaymentHistoryData(true)
    },

    selectPayDate() {
      const { payDate } = this.paymentHistoryPayload

      this.$router.push({
          path: '/artist/my-paid-offer-filter',
          query: {
              payDate,
          },
      })
    },
  },
}
</script>

<style lang="scss" scoped>

#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;
  .payment-list {
    padding: 0 3rem;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .list-option {
    overflow: hidden;
    border-top: 3px solid #eee;
    border-bottom: 2px solid #eee;
    

    > div {
        float: left;
        width: 50%;
        text-align: left;
        color: #919191;
        color: #000;
        font-size: 1.8rem;
        line-height: 5.5rem;
        padding: 0 3rem;

        &:first-child {
            border-right: 2px solid #eee;
        }

        .icon {
            float: right;
        }
    }
  }
}

</style>